const countdownElem = document.querySelectorAll('[data-timer]');

countdownElem.forEach((el) => {
  const eventTime = el.getAttribute('data-timer');
  const daysElem = el.querySelector('.timer__count-days');
  const hoursElem = el.querySelector('.timer__count-hours');
  const minutesElem = el.querySelector('.timer__count-minutes');
  // const secondsElem = el.querySelector('.timer__count-seconds');

  // Set the date we're counting down to
  const countDownDate = new Date(eventTime).getTime();

  // Update the count down every 1 second
  const x = setInterval(() => {
    // Get today's date and time
    const now = new Date().getTime();

    // Find the distance between now and the count down date
    const distance = countDownDate - now;

    const dayCount = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hoursCount = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesCount = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    // const secondsCount = Math.floor((distance % (1000 * 60)) / 1000);

    daysElem.innerHTML = dayCount.toLocaleString(undefined, { minimumIntegerDigits: 2 });
    hoursElem.innerHTML = hoursCount.toLocaleString(undefined, { minimumIntegerDigits: 2 });
    minutesElem.innerHTML = minutesCount.toLocaleString(undefined, { minimumIntegerDigits: 2 });
    // secondsElem.innerHTML = secondsCount.toLocaleString(undefined, { minimumIntegerDigits: 2 });

    // If the count down is over, write some text
    if (distance < 0) {
      clearInterval(x);
    }
  }, 1000);
});
